import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTableColumns, faUsers, faFileLines, faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../hooks/AuthContext';
import Tooltip from './ToolTip';

function SideBar() {
    const { logout } = useAuth();

    return (
        <div className="bg-gray-50 dark:bg-gray-800 h-full p-3 relative">
            <ul className="space-y-4">
                <li className='w-full h-full'>
                    <Tooltip tooltipText="Dashboard" placement="tooltip-right">
                        <a href="/dashboard?page=1" className={`flex items-center justify-center p-2 text-base font-normal hover:text-blue-500 rounded-lg text-white hover:bg-gray-700 ${window.location.href.includes("dashboard") ? "bg-gray-700" : "bg-gray-200"}`}>
                            <FontAwesomeIcon icon={faTableColumns} className={`text-xl ${window.location.href.includes("dashboard") ? "text-blue-500" : "text-blue-400"}`} />
                        </a>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip tooltipText="Roles" placement="tooltip-right">
                        <a href="/roles?page=1" className={`flex items-center justify-center p-2 text-base font-normal hover:text-blue-500 rounded-lg text-white hover:bg-gray-700 ${window.location.href.includes("roles") ? "bg-gray-700" : "bg-gray-200"}`}>
                            <FontAwesomeIcon icon={faUsers} className={`text-xl ${window.location.href.includes("roles") ? "text-blue-500" : "text-blue-400"}`} />
                        </a>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip tooltipText="Configurator" placement="tooltip-right">
                        <a href="/configurator?page=1" className={`flex items-center justify-center p-2 text-base font-normal hover:text-blue-500 rounded-lg text-white hover:bg-gray-700 ${window.location.href.includes("configurator") ? "bg-gray-700" : "bg-gray-200"}`}>
                            <FontAwesomeIcon icon={faFileLines} className={`text-xl ${window.location.href.includes("configurator") ? "text-blue-500" : "text-blue-400"}`} />
                        </a>
                    </Tooltip>
                </li>
            </ul>
            <ul className='absolute bottom-0 left-0 space-y-2 px-3 py-4 w-full'>
                <Tooltip tooltipText="Logout" placement="tooltip-right">
                    <li onClick={logout} className="flex cursor-pointer items-center p-2 text-base font-normal rounded-lg hover:text-blue-500 text-white hover:bg-gray-700">
                        <FontAwesomeIcon icon={faArrowRightFromBracket} className={`text-xl rotate-180`} />
                    </li>
                </Tooltip>
            </ul>
        </div>
    );
}

export default SideBar;