import { useEffect, useState } from "react";
import Form from "./Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faTruckLoading } from '@fortawesome/free-solid-svg-icons';
import CustomFieldCreateBtn from "../CustomFieldCreateBtn";
import { createStroies, deleteConfiguratorStoryCustomField, fetchConfiguratorStoryData , audioUpload ,audioConvert, projectTableColumnFetch, audioEnhancer ,fileUpload,deleteStoryData} from "../../services/apiService";
import { useNavigate, useSearchParams } from "react-router-dom";
import '../../custom.css'
import axios from 'axios'

interface StoryFormProps {
    setError: any;
    setIsError: any;
}

const StoryForm: React.FC<StoryFormProps> = ({ setError, setIsError }) => {
    const [storyCount, setStoryCount] = useState(0);
    const [storyData, setStoryData] = useState<any[]>([]);
    const [audioUrl, setAudioUrl] = useState('');
    const [currentStory,setCurrentStory] = useState('')
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingMes,setLoadingMes]= useState('')
    const [popupForAudioComparision,setPopupForAudioComparision] = useState(false)
    const [enhancedTextVersion,setEnhancedTextVersion] = useState('')
    const [alreadyAudioUploaded,setAlreadyAudioUploaded] = useState<Record<number, boolean>>({});
    const [alreadyImageUploaded,setAlreadyImageUploaded] = useState<Record<number, boolean>>({});
    const [audioUploaded,setAudioUploaded] = useState<Record<number, boolean>>({});
    const [imageUploaded,setImageUploaded] = useState<Record<number, boolean>>({});
    const [prevStoryId,setPrevStoryId] = useState<number[]>([]);

    const navigate = useNavigate();
    const fields = [
        { name: 'name', label: 'Storyline Title', placeholder: 'Story name' , limit:'20' },
        { name: 'description', label: 'Description', placeholder: 'Description' , limit:'100'  },
        { name: 'animation_type', label: 'Select Animation Type', placeholder: 'Animation type',type:'select' },

        { name: 'line_break', label: 'line break', placeholder: 'line break',type:'lineBreak'},
        // { name: 'already_image_upload_status', label: 'Icon Image', placeholder: 'Already image upload status',type:'status'},
        { name: 'select_image', label: 'Upload Icon Image(Ratio-1:1)(Res:300-500)', placeholder: 'select image' , type:'file'},
        { name: 'img_button', label: 'Upload Image', placeholder: 'Upload Image',type:'button'},
        // { name: 'icon_url', label: 'Icon URL', placeholder: 'Icon URL' },
        // { name: 'image_upload_status', label: 'Image upload status', placeholder: 'Image upload status',type:'status'},

        // { name: 'storyline_type', label: 'Storyline type', placeholder: 'Storyline type',type:'select', customField: false },
        // { name: 'object_url', label: 'Object URL', placeholder: 'Object URL', value: '' }

        { name: 'line_break', label: 'line break', placeholder: 'line break',type:'lineBreak'},
        // { name: 'already_audio_upload_status', label: 'Audio', placeholder: 'Already audio upload status',type:'status'},
        { name: 'Text_for_audio', label: 'Input Text For Voiceover', placeholder: 'Text for audio' , limit:'400'  },
        { name: 'audio_button', label: 'Convert to audio', placeholder: 'convert to audio',type:'button'},
        // { name: 'audio_url', label: 'Audio URL', placeholder: 'Audio URL' ,disabled:true},
        // { name: 'audio_upload_status', label: 'Audio upload status', placeholder: 'Audio upload status',type:'status'},
    ];
    const [fieldsCount, setFieldsCount] = useState<{ [key: string]: typeof fields }[]>([]);
    const [animation_type_list,setAnimation_type_list] = useState([])
    const [storyline_type_list,setStoryline_type_list] = useState([])

    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const transformData = (data: any) => {
        return {
            name: data.name,
            Text_for_audio: data.Text_for_audio,
            audio_url: data.audio_url,
            animation_type: data.animation_type,
            description: data.description,
            storyline_type: data.storyline_type,
            icon_url: data.icon_url,
            object_url: data.object_url,
            story_custom_fields: data.story_custom_fields,
        };
    };

    useEffect(() =>{
        async function colFetchFun1(){
            const res = await projectTableColumnFetch('Animation_type_list')
            const values = res.data.column_data.filter((val:string)=> (val !== null && val !== ''))
            setAnimation_type_list(values)

            const res2 = await projectTableColumnFetch('Storyline_type_list')
            const values2 = res2.data.column_data.filter((val:string)=> (val !== null && val !== ''))
            setStoryline_type_list(values2)
        }
        colFetchFun1()
        
    },[])

    useEffect(()=>{
            for(let i=0;i<storyData.length;i++){
                if(!(i in audioUploaded)){
                    setAudioUploaded(prevData => ({
                        ...prevData,
                        [i]:false
                    }))
               }

                if(!(i in imageUploaded)){
                    setImageUploaded(prevData => ({
                        ...prevData,
                        [i]:false
                    }))
                }

                if(storyData[i]['icon_url'] && storyData[i]['icon_url'] != ''){
                    setAlreadyImageUploaded(prevData => ({
                        ...prevData,
                        [i]:true
                    }))
                }
                else{
                    setAlreadyImageUploaded(prevData => ({
                        ...prevData,
                        [i]:false
                    }))
                }

                if(storyData[i]['audio_url'] && storyData[i]['audio_url'] != ''){
                    setAlreadyAudioUploaded(prevData => ({
                        ...prevData,
                        [i]:true
                    }))
                }
                else{
                    setAlreadyAudioUploaded(prevData => ({
                        ...prevData,
                        [i]:false
                    }))
                }
            
        }
    },[storyData])

    useEffect(() => {
        setStoryCount(storyData.length);
    }, [storyData]);

    useEffect(() => {
        const getData = async () => {
            try {
                const result = await fetchConfiguratorStoryData(String(code));
                if (result.status === 200) {
                    result.data.stories.map((data: any) => {
                        setPrevStoryId(prevData=>[
                            ...prevData,
                            data.id
                        ])
                    });
                    const transformedStories = result.data.stories.map((data: any) => transformData(data));
                    setStoryData(transformedStories);
                }
            } catch (error) {
                setIsError(true);
                if (error instanceof Error) {
                    setError('Error while getting data');
                } else {
                    setError("An unexpected error occurred while getting data");
                }
            } finally {
                setLoading(false);
            }
        };

        if (code != null) {
            getData();
        } else {
            setStoryData([]);
            setStoryCount(0);
        }
    }, [code, setError, setIsError]);

    const handleStoriesSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const result = await createStroies(storyData, code);
            if (result.status == 200) {
                navigate(`/configurator/form?page=features&code=${code}`)
            }
        } catch (error) {
            setIsError(true);
            if (error instanceof Error) {
                setError('Error while submitting');
            } else {
                setError("An unexpected error occurred while submitting");
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (storyCount === 0 && storyData.length === 0) {
            setFieldsCount([{ story0: [...fields] }]);
        } else if (storyData.length > 0) {
            const initialFields = Array.from({ length: storyCount }, (_, index) => ({
                [`story${index}`]: [...fields],
            }));

            initialFields[0] && storyData.forEach((data, index) => {
                const storyFields = initialFields[index][`story${index}`] || [];
                const storyCustomFields = data.story_custom_fields || {};
                const initialCustomFields = Object.values(storyCustomFields).map((customField: any) => ({
                    name: customField.attribute_value,
                    label: customField.attribute_value,
                    placeholder: customField.attribute_value,
                    value: customField.value,
                    customField: true,
                    type: customField.field_type,
                    id: customField.id
                }));

                if (storyFields) {
                    storyFields.push(...initialCustomFields);
                }
            });
            setFieldsCount(initialFields);
        } else if (fieldsCount.length < storyCount) {
            setFieldsCount(prev => {
                const newFields = Array.from({ length: storyCount - prev.length }, (_, index) => ({
                    [`story${prev.length + index}`]: [...fields]
                }));
                return [...prev, ...newFields];
            });
        }

    }, [storyCount, storyData]);

    const audioConvert1 = async (e:any,storyCount:any) => {
        e.preventDefault();
        setLoading(true)
        setLoadingMes('converting')
        try {
            // await   axios.post('https://api.xorostory.com/api/admin/audio/enhancer',{
            // await   axios.post('http://localhost:3000/api/admin/audio/enhancer',{
            //             text:storyData[Number(storyCount)]['Text_for_audio']
            //         })
            //         .then(async (response) =>{
            //             setEnhancedTextVersion(response.data.response.choices[0].message.content)
            //             setPopupForAudioComparision(true)
            //             setCurrentStory(storyCount)
            //         })
            //         .catch(error=>console.log(error))
            if(!storyData[Number(storyCount)]){
                alert('Enter storyline name')
            }
            else if(!storyData[Number(storyCount)]['name']){
                alert('Enter storyline name')
            }
            else if(storyData[Number(storyCount)]['name'] === '' || storyData[Number(storyCount)]['name'] === null){
                alert('Enter storyline name')
            }
            else if(!storyData[Number(storyCount)]['Text_for_audio']){
                alert('Enter text for audio convertion')
            }
            else if(storyData[Number(storyCount)]['Text_for_audio'] == '' || storyData[Number(storyCount)]['Text_for_audio'] == null){
                alert('Enter text for audio convertion')
            }
            else if(storyData[Number(storyCount)] && storyData[Number(storyCount)]['Text_for_audio']){
                const result = await audioEnhancer(storyData[Number(storyCount)]['Text_for_audio'])
                if(result.data.status && result.data.status == 'success'){
                    setEnhancedTextVersion(result.data.response.choices[0].message.content)
                    setPopupForAudioComparision(true)
                    setCurrentStory(storyCount)
                }
            }
            
        } catch (error) {
            setIsError(true);
            if (error instanceof Error) {
                setError('something went worng in text enhansion');
            } else {
                setError("An unexpected error occurred while text enhansion");
            }
        } finally {
            setLoading(false);
        }
    }

    const audioConvertionAfterComparision = async (e:any,selected:any) => {
        e.preventDefault();
        setPopupForAudioComparision(false)
        setLoading(true)
        setLoadingMes('converting')
        try {
            let text;
            if(selected === 'enhancedOne'){
                setStoryData(prevData => { 
                    const newData = [...prevData]; 
                    newData[Number(currentStory)]['Text_for_audio'] = enhancedTextVersion; 
                    return newData; 
                })
                text = enhancedTextVersion
            }
            else{
                text = storyData[Number(currentStory)]['Text_for_audio']
            }
            const result = await audioConvert(text);
            const url = URL.createObjectURL(result.data);
            setAudioUrl(url)
        } catch (error) {
            setIsError(true);
            if (error instanceof Error) {
                setError('error happend wile audio convertion');
            } else {
                setError("An unexpected error occurred wile audio convertion");
            }
        } finally {
            setLoading(false);
        }
    }

    // const audioConvert1 = async (e:any,storyCount:any) => {
    //     e.preventDefault();
    //     setLoading(true)
    //     setLoadingMes('converting')
    //     try {
    //         const result = await audioConvert(storyData[storyCount]['Text_for_audio']);
    //         const url = URL.createObjectURL(result.data);
    //         setAudioUrl(url)
    //         setCurrentStory(storyCount)
    //     } catch (error) {
    //         setIsError(true);
    //         if (error instanceof Error) {
    //             setError(error.message);
    //         } else {
    //             setError("An unexpected error occurred");
    //         }
    //     } finally {
    //         setLoading(false);
    //     }
        
    // }

    const audioUpload1 = async (e:any) => {
        e.preventDefault();
        setLoading(true)
        setLoadingMes('uploading')
        try {
            const result = await audioUpload(storyData[Number(currentStory)]['Text_for_audio'],storyData[Number(currentStory)]['name'],'storyline',localStorage.getItem('project_name'));
            if(result.status == 200){
                setAudioUploaded(prevData => ({
                    ...prevData,
                    [Number(currentStory)]:true
                }))
                setStoryData((prevData) => {
                    const updatedProjectData = [...prevData];
                    if (updatedProjectData.length > 0) {
                        updatedProjectData[Number(currentStory)].audio_url = result.data.s3_key;
                      }
                      else {
                        updatedProjectData.push({ audio_url: result.data.s3_key });
                      }
                    return updatedProjectData;
                });
                setAudioUrl('')
                alert('audio uploaded successfully')
            }
        } catch (error) {
            setIsError(true);
            if (error instanceof Error) {
                setError('error while audio uploding process');
            } else {
                setError("An unexpected error occurred");
            }
        } finally {
            setLoading(false);
        }
    }

    const image_upload = (formData:any, storyCount:any) => {
        setLoading(true);
        setLoadingMes('uploading');
        return new Promise((resolve, reject) => {
            const file = formData.get('file');
            const fileName = file.name;
            const fileExtension = fileName.split('.').pop();
            if (fileExtension === 'png' || fileExtension === 'jpeg') {
                const img = new Image();
                img.onload = async () => {
                    if (img.width >= 300 && img.width <= 500) {
                        if (img.width === img.height) {
                            const result = await fileUpload(formData, 'storyline', localStorage.getItem('project_name'));
                            setImageUploaded(prevData => ({
                                ...prevData,
                                [storyCount]: true
                            }));
                            setStoryData((prevData) => {
                                const updatedProjectData = [...prevData];
                                updatedProjectData[storyCount].icon_url = result.data.s3_key;
                                return updatedProjectData;
                            });
                            setLoading(false);
                            resolve(result);
                        } else {
                            alert('Same width and height (Ex: 300x300) are allowed for icon image');
                            setLoading(false);
                            resolve({ status: 2000 });
                        }
                    } else {
                        alert('Only "300 - 500" width allowed for icon image');
                        setLoading(false);
                        resolve({ status: 2000 });
                    }
                };
                img.onerror = () => {
                    alert('Error loading image');
                    setLoading(false);
                    reject(new Error('Error loading image'));
                };
                img.src = URL.createObjectURL(file);
            } else {
                alert('Only ".png" and ".jpeg" are allowed for branding image');
                setLoading(false);
                resolve({ status: 2000 });
            }
        });
    };
    

    const handleStoryChange = (
        e: (React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>),
        index: number,
        field: boolean
    ) => {
        const { name, value, type } = e.target;

        setStoryData((prev) => {
            const updatedStories = [...prev];
            const story = updatedStories[index] || {};
            story[name] = value;
            if (field) {
                const storyCustomFields = story.story_custom_fields || {};
                const fieldIndex = Object.keys(storyCustomFields).findIndex(key => storyCustomFields[key].attribute_value === name);
                if (fieldIndex !== -1) {
                    storyCustomFields[fieldIndex] = {
                        ...storyCustomFields[fieldIndex],
                        value: value,
                    };
                } else {
                    storyCustomFields[Object.keys(storyCustomFields).length] = {
                        id: storyCustomFields[Object.keys(storyCustomFields).length]?.id,
                        attribute_value: name,
                        field_type: type,
                        value: value,
                    };
                }
                story.story_custom_fields = storyCustomFields;
            }
            updatedStories[index] = story;
            return updatedStories;
        });
    };


    const handleFieldsDeletion = (storyKey: string) => {
        const storyIndex = fieldsCount.findIndex(item => storyKey in item);
        if (storyIndex === -1) {
            console.log('Story key not found');
            return;
        }
        setStoryData((prev: any) => {
            const updatedStories = prev.filter((_: any, index: number) => index !== storyIndex);
            return updatedStories;
        });
        setFieldsCount((prev) => {
            const newFieldsCount = prev.filter((story, index) => {
                return Object.keys(story)[0] !== storyKey;
            });
            return newFieldsCount;
        });
        setStoryCount((prev: any) => prev - 1);
    };

    const handleDeleteCustomField = (id: any, name: any, index: any) => {
        const fieldIndex = fieldsCount[index][`story${index}`].findIndex((item: { name: any }) => item.name === name);
        if (fieldIndex !== -1 && fieldIndex) {
            setStoryData((prev: any[]) => {
                const updatedStory = prev.map(story => {
                    const storyCustomFields = [...(story.story_custom_fields || [])];
                    const fieldIndex = storyCustomFields.findIndex((item: { attribute_value: any }) => item.attribute_value === name);
                    if (fieldIndex !== -1) {
                        storyCustomFields.splice(fieldIndex, 1);
                    }
                    return {
                        ...story,
                        story_custom_fields: storyCustomFields
                    };
                });
                return updatedStory;
            });
            setFieldsCount((prev: any) => {
                const updatedStory = [...prev];
                const story = updatedStory[index][`story${index}`];
                story.splice(fieldIndex, 1)
                return updatedStory;
            });
        }
        if (id !== undefined) {
            deleteConfiguratorStoryCustomField(id);
        }
    }

    const handleFieldCreateSubmit = (input: any, index: any) => {
        setStoryData((prev: any[]) => {
            const updatedStory = [...prev];
            const story = updatedStory[index] || {};
            const storyCustomFields = story.story_custom_fields || {};
            storyCustomFields[Object.keys(storyCustomFields).length] = {
                id: storyCustomFields[Object.keys(storyCustomFields).length]?.id,
                attribute_value: input.name,
                field_type: input.type,
                value: '',
            };
            story.story_custom_fields = storyCustomFields;
            updatedStory[index] = story;
            return updatedStory;
        });
    }

    const deleteStory = async (e:any,index:any) => {
        e.preventDefault();
        const result = await deleteStoryData(code,prevStoryId[index])
        if(result.status == 200){
            alert('Story deleted successfully')
            window.location.reload();
        }
    }

    return (
        <div>
            <div style={{position:'relative'}}>
                <form onSubmit={handleStoriesSubmit}>
                    <div className="px-10 flex justify-between items-center">
                        <div className="px-10 py-5 ps-0 text-lg flex">
                                <p className='mr-4 text-xl' style={{fontSize:'30px',color:'#0050C8'}}>Storyline Setup </p>
                        </div>
                        <button
                            type="submit"
                            style={{backgroundColor:'#058C7E'}}
                            className="text-white hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 rounded-lg text-sm px-5 py-2.5 text-center w-1/5 font-bold"
                        >
                            Save & Proceed
                        </button>
                    </div>
                    <div className='h-[73vh] w-full overflow-y-auto '>
                        {Array.isArray(fieldsCount) && fieldsCount.map((fieldsObj, index) => {
                            const storyKey = Object.keys(fieldsObj)[0];
                            return (
                                <div key={index}>
                                    <div className="px-10 py-5 text-lg flex items-center">
                                        <span className='mr-4 text-xl' style={{color:'#0050C8'}}>Storyline {index + 1}</span>
                                        {prevStoryId.length !== 0 && 
                                            <button 
                                                style={{backgroundColor:'rgb(219, 3, 3)'}}
                                                className="text-white hover:bg-red-400 focus:ring-4 focus:outline-none focus:ring-red-300 rounded-lg text-sm px-1.5 py-0.5 text-center font-bold"
                                                onClick={(e)=>deleteStory(e,index)}
                                            ><FontAwesomeIcon icon={faTrash} className='text-sm' /></button>
                                        }
                                        {/* {index !== 0 && <button type="button" onClick={() => handleFieldsDeletion(storyKey)} className="flex items-center"><FontAwesomeIcon icon={faTrash} className='text-sm' /></button>} */}
                                    </div>
                                    <div className='w-full h-auto flex'>
                                        <div className='w-full px-5 flex flex-wrap'>
                                            <Form fields={fieldsObj[storyKey]} handleChange={(e: (React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>), field) => handleStoryChange(e, index, field)} data={storyData[index] ?? []} handleDeleteCustomField={(id: any, name: any) => handleDeleteCustomField(id, name, index)} audioConvert={(e:any,storyCount:any)=>audioConvert1(e,storyCount)} selectedTextForConvertion={storyData[index]?.Text_for_audio} story_audio={storyData[index]?.audio_url} storyCount={(index).toString()} animation_type_list={animation_type_list} storyline_type_list={storyline_type_list} image_upload={(e:any)=>image_upload(e,index)} imageUploaded={imageUploaded[index]} audioUploaded={audioUploaded[index]} alreadyAudioUploaded={alreadyAudioUploaded[index]} alreadyImageUploaded={alreadyImageUploaded[index]}/>
                                            <div style={{flexBasis: '100%',height:'0'}}></div>
                                            <div className="mb-5 mx-5 w-1/5 items-end justify-end flex">
                                                <CustomFieldCreateBtn index={index} keyName={`story${index}`} setFieldsCount={setFieldsCount} handleFieldCreateSubmit={(inputValue: any) => handleFieldCreateSubmit(inputValue, index)} />
                                            </div>
                                            <div style={{flexBasis: '100%',height:'0'}}></div>
                                            {index === fieldsCount.length-1 &&
                                            <button
                                                    type='button'
                                                    onClick={() => setStoryCount((prev: any) => prev + 1)}
                                                    className="text-blue-600 mb-5 mx-5 bg-blue-700 border mx-10 border-blue-600 hover:bg-blue-800 hover:text-white bg-transparent rounded-lg px-3 py-1.5 text-center text-sm"
                                                >
                                                    <FontAwesomeIcon icon={faPlus} className='text-sm' />  Add storyline
                                            </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </form>
                {audioUrl && 
                    ( 
                        <div className="flex items-center justify-center mt-3" style={{position:'absolute',top:'0',left: '50%',transform: 'translateX(-50%)'}}> 
                            <audio controls className="me-2"> 
                                <source src={audioUrl} type="audio/wav" /> Your browser does not support the audio element. 
                            </audio> 
                            <button className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-sm text-center p-2.5 font-bold" onClick={(e)=>{audioUpload1(e)}} >upload</button>
                        </div> 
                    )
                }
            </div>
            {loading &&
                <div style={{position:'fixed',top:'0',bottom:'0',width:'100%',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
                    <div style={{background:'white',border:' 2px solid #0050C8'}} className="flex items-center rounded px-7 py-3">
                        <div className="spinner me-2"></div>
                        {
                            loadingMes === 'converting' ?  (<p className="text-blue-700">Converting please wait...</p>)  : loadingMes === 'uploading' ? <p className="text-blue-700">Uploading please wait...</p> : <p className="text-blue-700">Loading please wait...</p> 
                        }
                    </div>
                </div>
            }
            {popupForAudioComparision &&
                <div style={{position:'fixed',top:'0',bottom:'0',width:'100%',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
                    <div style={{background:'white',padding:'10px',width:'80%',height:'500px'}}>
                        <div className="w-full h-[20%] flex items-center justify-center">
                            <p className="text-center text-xl font-bold" style={{fontSize:'30px'}}>Please select one of the versions for audio conversion</p>
                        </div>
                        <div style={{background:'white',padding:'10px',width:'100%',display:'flex',alignItems:'start',justifyContent:'space-around',height:'80%'}}>
                            <div className="overflow-hidden w-[45%]  border h-full p-2">
                                <h4 className="text-center mb-4 underline">Previous One</h4>
                                <div className="overflow-y-auto h-[70%]">
                                    <p>{storyData[Number(currentStory)]['Text_for_audio']}</p>
                                </div>
                                <div className="flex justify-end mt-3">
                                    <button onClick={(e)=>(audioConvertionAfterComparision(e,'previousOne'))} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Your Text</button>
                                </div>
                            </div>
                            <div className="w-[1px] h-full bg-black"></div>
                            <div className="w-[45%] overflow-hidden overflow-y-auto border h-full p-2">
                                <h4 className="text-center mb-4 underline">Enhanced One</h4>
                                <div className="overflow-y-auto h-[70%]">
                                    <p>{enhancedTextVersion}</p>
                                </div>
                                <div className="flex justify-end mt-3">
                                    <button onClick={(e)=>(audioConvertionAfterComparision(e,'enhancedOne'))} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Enhanced Text</button>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            }
        </div>
    );
};

export default StoryForm;
