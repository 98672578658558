import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import TableViewLayout from '../../layouts/TableViewLayout';
import { useSearchParams } from 'react-router-dom';
import { fetchConfiguratorProjectsDatas } from '../../services/apiService';

interface DashboardProps {
  setError: Dispatch<SetStateAction<string | null>>;
  setIsError: Dispatch<SetStateAction<boolean>>;
}

const Configurator: React.FC<DashboardProps> = ({ setError, setIsError }) => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');

  const updatePageParam = (newPage: any) => {
    searchParams.set('page', newPage.toString());
    setSearchParams(searchParams);
  };

  const handlePageChange = async (page: Number) => {
    updatePageParam(page);
    const result = await fetchConfiguratorProjectsDatas(page);
    setData(result);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await fetchConfiguratorProjectsDatas(Number(page));
        setData(result);
      } catch (error) {
        setIsError(true);
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError("An unexpected error occurred");
        }
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [page, setError, setIsError]);

  const breadcrumbs = [
    { label: 'Configurator', href: '' },
  ];

  const desiredValues = data != null ? data.projects.map((item: { name: any; code: any; stories: any; features: any; objects: any; company: any; }) => ({
    company: item.company,
    project: item.name,
    code: item.code,
    storylines: item.stories,
    features: item.features,
    // objects: item.objects
  })) : [{
    company: null,
    project: null,
    code: null,
    storylines: null,
    features: null,
    // objects: null
  }];

  return (
    <div className='w-full h-full'>
      <div className='flex justify-between items-center pr-5'>
        <Breadcrumb breadcrumbs={breadcrumbs} />
        <div><a href='/configurator/form?page=project' className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Add new project</a></div>
      </div>
      <div className='bg-yellow-500 w-full h-auto rounded-t-xl p-3'>
        {TableViewLayout({
          data: {
            clickable_value: data && data.projects,
            clickable_link_type: "configurator",
            top: null,
            headers: ["company", "project", "code", "storylines", "features"],
            values: desiredValues,
            total_pages: data && data.total_pages,
            current_page: data && data.current_page,
            title: "Configurator",
            sub_title: "Projects List"
          },
          handlePageChange: handlePageChange,
        })}
      </div>
    </div>
  );
};

export default Configurator;